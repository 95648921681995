import noah5 from "../images/final/noah2-800.webp";
import flaxenHair from "../audio/recordings/flaxen-hair-debussy.mp3";
import loveBallad from "../audio/recordings/love-ballad-oscar-peterson.mp3";
import skylark from "../audio/recordings/skylark-hoagy-carmichael.mp3";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import YouTube from "react-youtube";
import { useEffect } from "react";

export default function Shows() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const opts = {
    height: "100%",
    width: "100%",
    // height: "559",
    // width: "994",
  };
  return (
    <main>
      <h1 className="purple">Music</h1>
      <div className="music-about margin-bottom-med">
        <img
          src={noah5}
          alt="Noah"
          className="mobile-full-width music-pic margin-bottom-small-mobile"
          width="795"
          height="1193"
        />
        <div className="about-text">
          <p>
            With 15 years of experience as a pianist, I can play classical,
            jazz, pop, and anything inbetween.
          </p>
          <p>
            I play piano as background music for weddings, cocktail parties, and
            corporate functions.
          </p>
          <p>I also teach piano lessons for students of all ages.</p>
        </div>
      </div>
      <h2 className="purple">Solo Piano</h2>
      <YouTube
        videoId="eb-XJIiS3OU"
        opts={opts}
        loading="lazy"
        className="mobile-full-width youtube "
      />
      <em className="smaller-text">
        30 minutes of improvisized jazz piano prior to a comedy show at Cafe
        Coda
      </em>
      <YouTube
        videoId="i0UJ2ZLiHzQ"
        opts={opts}
        loading="lazy"
        className="mobile-full-width youtube margin-top-med"
      />
      <h2 className="margin-top-med purple">Recordings</h2>
      <div className="recordings">
        <div className="track">
          <div>The Girl with the Flaxen Hair - Debussy</div>
          <AudioPlayer src={flaxenHair} />
        </div>
        <div className="track">
          <div>Love Ballad - Oscar Peterson</div>
          <AudioPlayer src={loveBallad} />
        </div>
        <div className="track">
          <div>Skylark - Hoagy Carmichael</div>
          <AudioPlayer src={skylark} />
        </div>
      </div>

      {/* <h2>Ideas</h2>
      <h2>Bits</h2>
      
      <h2>Compositions</h2>
      */}
    </main>
  );
}
