import ccc from "../images/dev/ccc/ccc-card-750.webp";
import cock from "../images/dev/cocknoodlesoup/cock-card-750.webp";
import piano from "../images/dev/mjpiano/mjpiano-card-750.webp";
import ProjectCard from "./projects/ProjectCard";
import noah6 from "../images/final/noah6-800.webp";
import { useEffect } from "react";

export default function Shows() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <h1 className="green">Development</h1>
      <div className="dev-about margin-bottom-med">
        <div className="about-text">
          <p>
            I design and develop custom hand-coded websites without relying on
            stock templates.
          </p>
          <p>
            I draw inspiration from many sources and incorporate the best
            aspects of what I see to give every website I build its own unique
            look and feel.
          </p>

          <p>
            Every website I build is optimized to function well and look great
            on all devices.
          </p>
          <p>Scroll down to see examples of my completed projects.</p>
        </div>
        <img
          src={noah6}
          alt="Noah"
          className="mobile-full-width music-pic margin-bottom-small-mobile"
          width="795"
          height="1193"
        />
      </div>
      <h2 className="green">Projects</h2>
      <div className="project-cards margin-bottom-small">
        <ProjectCard
          img={ccc}
          title="Cheshire Cat Comedy"
          localLink="ccc"
          liveLink="https://cheshirecatcomedy.com"
        ></ProjectCard>
        <ProjectCard
          img={cock}
          title="Cocknoodlesoup"
          localLink="soup"
          liveLink="https://cocknoodlesoup.com"
        ></ProjectCard>
        <ProjectCard
          img={piano}
          title="Matt Jordan's Piano Studio"
          localLink="mjpiano"
          liveLink="https://mattjordanpianostudio.com/"
        ></ProjectCard>
        <div className="blank"></div>
      </div>
      {/* <a href="https://github.com/clamlands" target="_blank" rel="noreferrer">
        My Github
      </a> */}
    </main>
  );
}
